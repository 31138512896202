import { configureStore } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import screenReducer from './screenSlice';
import availabilityReducer from './availabilitySlice';
import searchSlice from './searchSlice';
import homeSlice from './homeSlice';

export default configureStore({
	reducer: {
		auth: authReducer,
		screen: screenReducer,
		availability: availabilityReducer,
		search: searchSlice,
		home: homeSlice,
	},
});
