import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	text: '',
	trendingSearches: [],
	suggestions: [],
};

const searchSlice = createSlice({
	name: 'search',
	initialState: initialState,
	reducers: {
		set: (state, action) => ({
			...state,
			...action.payload,
		}),
	},
});

export const { set } = searchSlice.actions;

export default searchSlice.reducer;
