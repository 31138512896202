import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  screenWidth: window.innerWidth,
  footerHeight: 0,
  headerHeight: 0,
  theme: 'light',
  showWelcomeModal: false,
};

const screenSlice = createSlice({
  name: 'screen',
  initialState: initialState,
  reducers: {
    set: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { set } = screenSlice.actions;

export default screenSlice.reducer;
