import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  authenticated: false,
  user: null,
  supportNumber: '+917987436563',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    set: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    reset: (state, action) => initialState,
    setSupportNumber: (state, action) => {
      return { ...state, supportNumber: action.payload };
    },
  },
});

export const { set, reset, setSupportNumber } = authSlice.actions;

export default authSlice.reducer;
