const lightTheme = {
  primaryColor: "#ff6200",
  secondaryColor: "#10243e",
  tertiaryColor: "#edf2fb",
  headerTextColor: "#798294",
  borderColor: "#E1E1E1",
  borderSecondaryColor: "#C2CCDF",
  borderTertiaryColor: "#939393",
  borderQuaternaryColor: "#E1E3E0",
  primaryBGColor: "#fff",
  bgColor: "#F6F8FA",
  bgSecondaryColor: "#AAAFBB",
  bgTertiaryColor: "#F8FAFF",
  modalColor: "#00000088",
  textColor: "#798294",
  labelColor: "#3a3a3a",
};

const darkTheme = {
  primaryColor: "#ff6200",
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

export default themes;
