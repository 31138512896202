import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	pincode: '',
};

const availabilitySlice = createSlice({
	name: 'availability',
	initialState: initialState,
	reducers: {
		set: (state, action) => ({
			...state,
			...action.payload,
		}),
		reset: (state, action) => (initialState)
	},
});

export const { set, reset } = availabilitySlice.actions;

export default availabilitySlice.reducer;
